<template>
  <div id='cart-summary'>
    <div class="module-content">
      <div class="module-header">
        <h3 class="title">{{ $t(titleKey) }}</h3>
        <base-button
          :isDisabled="!cartHasItems"
          :isLoading="isEmptyBtnLoading"
          :isSecondary="true"
          :text="$t(emptyBtnKey)"
          @clicked="handleEmptyBtnClick"
        />
      </div>
      <div class="module-body">
        <div class="summary-table">
          <div v-for="(row, i) in tableRows" :key="i" class="table-row">
            <span class="label">{{ row.labelText }}</span>
            <span class="value">{{ row.value }}</span>
          </div>
        </div>
        <div class="buttons">
          <base-button :isDisabled="!cartHasItems" :isSecondary="true" :text="$t(payBtnKey)" @clicked="handleBtnClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'CartSummary',
  props: {
    titleKey: {
      type: String,
      required: false,
      default: 'pages.cart.summary.title',
    },
    emptyBtnKey: {
      type: String,
      required: false,
      default: 'pages.cart.summary.buttons.empty.text',
    },
    payBtnKey: {
      type: String,
      required: false,
      default: 'pages.cart.summary.buttons.pay.text',
    },
    btnRoutePath: {
      type: String,
      required: false,
      default: '/payment/registration',
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
    isEmptyBtnLoading: false,
  }),
  created() {},
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
    cartHasItems() {
      if (this.getCurrentCart) {
        return this.getCurrentCart.cartItems.length > 0;
      }

      return false;
    },
    tableRows() {
      return [
        {
          labelText: this.$t('pages.cart.summary.rows.ticketsLabel'),
          value: this.$options.filters.toCurrency(
            this.getCurrentCart && this.getCurrentCart.price ? this.getCurrentCart.price.netUnitPrice : 0,
          ),
        },
        {
          labelText: this.$t('pages.cart.summary.rows.taxLabel'),
          value: this.$options.filters.toCurrency(
            this.getCurrentCart && this.getCurrentCart.price ? this.getCurrentCart.price.vatUnitPrice : 0,
          ),
        },
        {
          labelText: this.$t('pages.cart.summary.rows.summaryLabel'),
          value: this.$options.filters.toCurrency(
            this.getCurrentCart && this.getCurrentCart.price
              ? this.getCurrentCart.price.netUnitPrice + this.getCurrentCart.price.vatUnitPrice
              : 0,
          ),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      clearCart: 'cart/clearCart',
    }),
    handleEmptyBtnClick() {
      this.isEmptyBtnLoading = true;
      this.clearCart(this.getCurrentCart._id).then(() => {
        this.isEmptyBtnLoading = false;
      });
    },
    handleBtnClick() {
      this.$router.push(this.btnRoutePath);
    },
  },
};
</script>

<style lang='scss' scoped>
.module-content {
  background-color: $white;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  padding: 30px 36px 31px 33px;
}

.module-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 22px;

  @media screen and (max-width: $breakpointDownMd) {
    flex-wrap: wrap;
  }

  .title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    line-height: 1;
  }

  .button-base {
    background-color: $primaryOrange;
    box-shadow: none;
    padding: 0 15px;
    height: 25px;
    line-height: 25px;
    margin: 0;
    min-width: 110px;

    @media screen and (max-width: $breakpointDownMd) {
      margin-top: 10px;
    }

    &::v-deep {
      .loader {
        height: 25px;
      }
    }
  }
}

.table-row {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  padding: 18px 0;
  position: relative;

  .label {
    padding-right: 15px;
  }

  &:after {
    content: '';
    width: 100%;
    max-width: 255px;
    height: 3px;
    background-color: $quantityInputBackground;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: $breakpointDownSm) {
      max-width: 90%;
    }
  }

  &:nth-child(2n) {
    padding: 22px 0;

    .label {
      &:before {
        content: '+';
        padding-right: 5px;
        display: inline-block;
      }
    }
  }

  &:last-child() {
    font-size: 20px;

    .value {
      font-weight: 700;
    }

    &:after {
      display: none;
    }
  }
}

.buttons {
  margin: 20px 0 0;
}
</style>
